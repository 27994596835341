.static-page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: var(--color-active-dark);
    color: var(--color-passive-mark);
    overflow-y: auto;

    &.locked {
        cursor: wait;

        * {
            pointer-events: none !important;
        }
    }
}
  