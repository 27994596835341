.identity-field{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    &__input{
        margin-right: 10px;
        padding: 2px;
        border: 1px solid grey;        
    }

    &__error{
        border: 2px solid red;
    }
}