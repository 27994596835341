.digital-wallet-body {
    background-color: #fff;
}

.digital-wallet-container {
    padding-top: 24px;
    background-color: #fff;
    color: #000;
    min-height: 100%;
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    padding-bottom: 180px;

    & > :not(.public-loader) {
        max-width: 320px;
        margin: 0 auto;
    }

    .loading-overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        max-width: 100vw;
        height: 100%;
        background-color: rgba( 0, 0, 0, .5 );
    }

    .public-card-header:after {
        margin: 8px 0;
    }

    .digital-wallet-content {
        padding: 0px 20px;

        h2 {
            color: #000;
            text-align: center;            
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 16px;
        }

        p {
            font-weight: 500;
            font-size: 16px;
        }
    }

    .digital-wallet-points {
        margin: 20px 0;
        padding: 0 20px;

        li {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 24px;            
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
        }

        .image-container {
            background-color: transparent;
            flex: 0 0 28px;
            width: 28px;
            height: 28px;
            margin-right: 24px;
        }
    }

    .digital-wallet-sticky {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100vw;
        max-width: 100vw;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        background-color: #fff;
        pointer-events: none;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 40px;
            width: 100%;
            max-width: 100%;
            background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
            transform: translateY(-100%);
        }

        > * {
            max-width: 320px;
            margin: 0 auto;
        }
    }

    .digital-wallet-buttons {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
        padding: 0 20px;
        margin-top: auto;
        width: 100%;

        .ant-btn,
        img {
            width: auto;
            flex: 1 0 calc(50% - 4px);
            max-width: calc(50% - 4px);
            border-radius: 10px !important;
            height: 44px;
            pointer-events: all;
        }

        .ant-btn {       
            font-weight: 600;     
            font-size: 17px;
            line-height: 23px;
        }
    }

    .digital-wallet-preview {
        font-size: 0;
        position: relative;
        
        > img {
            width: 100%;
            margin: 16px 0 0;
        }
    }

    .dw-preview-container {
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 73.125%;
        transform: translateX(-50%);
        height: 54px;
        overflow: hidden;

        &.swap-clone {
            .dw-preview-card,
            .dw-preview-card-current,
            .dw-preview-card + .dw-preview-card {
                transition: none
            }
        }

        .dw-preview-card {
            width: 100%;
            height: 108px;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 10px;
            transition: transform 1s ease;
            transform: translateX(-120%) translateZ(0);

            &.dw-card-current {
                transform: translateX(0) translateZ(0);

                & + .dw-preview-card {
                    transition: none;
                    transform: translateX(120%) translateZ(0);
                }
            }

            img {
                position: absolute;
                left: 13px;
                top: 13px;
                width: auto;
                max-width: 120px;
                height: 30px;
                object-fit: contain;
            }

            .dw-preview-verified {
                position: absolute;
                right: 13px;
                top: 15px;
                width: auto;
                height: 23px;

                svg {
                    width: auto;
                    height: 100%;
                }
            }
        }
    }
}

@media (prefers-color-scheme: dark) {
    .digital-wallet-body {
        background-color: #000;
    }

    .digital-wallet-container {
        background-color: #000;
        color: #fff;

        .digital-wallet-sticky {
            background-color: #000;

            &:before {
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            }
        }

        .digital-wallet-content {
            h2 {
                color: #fff;
            }
        }
    
    }
}