.ant-radio-wrapper {
    &:hover .ant-radio, 
    .ant-radio:hover .ant-radio-inner, 
    .ant-radio-input:focus + .ant-radio-inner,
    .ant-radio-checked .ant-radio-inner {
        border-color: var(--color-active-brand);
    }
    .ant-radio-inner::after {
        background-color: var(--color-active-brand);
    }
    .ant-radio-checked::after {
        border-color: var(--color-active-brand);
    }
}