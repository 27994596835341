.ant-modal-content {
    border-radius: 1rem;
}

.ant-modal-header {
    background-color: transparent;
    padding: 2.25rem 3rem 1.5rem;
    border: none;
}

.ant-modal-body {
    padding: 0 3rem 3.75rem;
    font-size: 2rem;
}

.ant-modal-footer {
    padding: 0 1.5rem 1.25rem;
    border: none;

    .ant-btn {
        border-radius: .5rem !important;
    }
}