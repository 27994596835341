.tabee-login__offset-wrap {
    margin-top: 1rem;
}

.ant-form {
    margin: 1rem 0;

    .ant-row {
        display: flex;
        align-items: flex-start;
        text-align: left;
        margin-bottom: 0;

        & + & {
            margin-top: 1px;
        }

        &.offset {
            margin-top: 1rem;
        }
    }
      
    .form-row-label {
        display: flex;
        align-items: center;
        width: 18rem;
        min-height: 4rem;
        margin: 0.5rem 0;
        color: var(--color-passive-mark);
    }
      
    .form-row-control {
        width: 29rem;
        min-height: 4rem;
        margin: 0.5rem 0;

        &:first-child {
            margin-left: 18rem;
        }
        & + & {
            width: auto;
            margin-left: 2rem;
        }
    }
      
    .form-control {
        padding: 1rem 0;
        text-align: center;
    }

    .ant-form-item-control-input-content .ant-picker {
        width: 100%;
    }
}

.ant-form-item-label > label {
    font-size: 1.625rem;
}

.ant-form-item-label > label::after {
    display: none;
}