body.scroll-lock {
    overflow-y: hidden;
}

.full-popup {
    .custom-design-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        & + .custom-design-line {
            margin-top: 20px;
        }
    }

    .custom-design-card {
        flex: 1 1 100%;
        padding: 20px 20px;
        border-radius: 20px;

        h4 {
            margin: 0 0 10px;
        }
    }

    .custom-design-icon {
        flex: 0 0 20px;
        margin: 0 0 0 20px;

        .cdi-add {
            flex: 0 0 24px;
            width: 24px;
            height: 24px;
            background: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNTYiIGhlaWdodD0iMTU2IiBmaWxsPSJub25lIj48cmVjdCB3aWR0aD0iMTEiIGhlaWdodD0iNzEiIHg9IjcyIiB5PSI0MiIgZmlsbD0iI2ZmZiIvPjxyZWN0IHdpZHRoPSIxMSIgaGVpZ2h0PSI3MSIgeD0iMTEzIiB5PSI3MiIgZmlsbD0iI2ZmZiIgdHJhbnNmb3JtPSJyb3RhdGUoOTAgMTEzIDcyKSIvPjwvc3ZnPg==) no-repeat center center;
            background-size: cover;
            background-color: #23b7f9;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            font-weight: 800;
            color: #ebedef;
            text-align: center;
            transform: scale(1.2);
        }

        .cdi-check {
            width: 16px;
            height: 20px;
            border: 4px solid #23b7f9;
            border-top: none;
            border-left: none;
            transform: rotate(45deg);
        }

        .cdi-right {
            width: 15px;
            height: 15px;
            border: 4px solid #23b7f9;
            border-top: none;
            border-left: none;
            transform: rotate(-45deg);
        }
    }
}

@media (prefers-color-scheme: dark) {
    .full-popup {
        .custom-design-icon {
            .cdi-add {            
                color: #1b1b1b;
                background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNTYiIGhlaWdodD0iMTU2IiBmaWxsPSJub25lIj4NCiAgcm4NCiAgPHJlY3Qgd2lkdGg9IjExIiBoZWlnaHQ9IjcxIiB4PSI3MiIgeT0iNDIiIGZpbGw9IiMwMDAiLz4NCiAgcm4NCiAgPHJlY3Qgd2lkdGg9IjExIiBoZWlnaHQ9IjcxIiB4PSIxMTMiIHk9IjcyIiBmaWxsPSIjMDAwIiB0cmFuc2Zvcm09InJvdGF0ZSg5MCAxMTMgNzIpIi8+DQogIHJuDQo8L3N2Zz4=);
            }
        }
    }
}