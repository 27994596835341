.ant-input,
.ant-input-password,
.ant-input-affix-wrapper {
    width: 100%;
    height: 4rem;
    padding: 0 1rem;
    background: var(--color-passive-light);
    border: 1px solid var(--color-passive-medium);
    color: var(--color-active-dark);
    transition: all 0.25s;
    margin-bottom: 1px;
    border-radius: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.7rem;
    line-height: 4rem;
    
    &:not(:disabled):focus {
        background-color: var(--color-passive-light);
        border-color: var(--color-active-brand);
    }
    &:disabled,
    &[readonly] {
        color: var(--color-passive-mark);
    }

    &:-internal-autofill-selected {
        color: var(--color-active-dark) !important;
        -webkit-text-fill-color: var(--color-active-dark);
    }

    &.dark-theme {
        background-color: var(--color-passive-dark);
        border-color: var(--color-passive-dark);
        color: var(--color-passive-light);
        box-shadow: inset 0 0 0 6.25rem var(--color-passive-dark);
        -webkit-text-fill-color: var(--color-passive-light);
        caret-color: var(--color-passive-light);

        &::placeholder {
            -webkit-text-fill-color: var(--color-passive-light);
            color: var(--color-passive-light);
            opacity: .5;
        }
        
        &:not(:disabled):focus,
        &:not(.ant-input-affix-wrapper-disabled):hover {
            background-color: var(--color-passive-dark);
            border-color: var(--color-passive-dark);
        }
        &:disabled,
        &[readonly] {
            color: var(--color-passive-mark);
            -webkit-text-fill-color: var(--color-passive-mark);
        }

        .ant-input,
        .ant-input:focus {
            box-shadow: inset 0 0 0 6.25rem var(--color-passive-dark);
        }

        .ant-input::placeholder {
            -webkit-text-fill-color: var(--color-passive-light);
            color: var(--color-passive-light);
            opacity: .5;
        }

        .ant-input-password-icon {
            color: var(--color-passive-mark);
        }
    }

    .ant-input {
        border: none;
        background: transparent !important;
        color: currentColor !important;
        height: auto;
    }
}

.ant-input-affix-wrapper-disabled {
    cursor: not-allowed;

    &:hover {
        border-color: var(--color-passive-medium);
    }

    * {
        pointer-events: none;
    }
}