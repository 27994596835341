.public-card-header {
    padding: 32px 0 0;
    margin-bottom: 24px;
    text-align: center;
    width: 100%;
    
    .public-card-logo {
        width: 124px;
        height: 22px;
    }
    
    &:after {
        content: '';
        background: url(https://server.tabee.mobi/static/tabee/images/mails/dark/divider.png) no-repeat center center;
        background-size: contain;
        display: block;
        margin-top: 32px;
        height: 25px;
    }
}

@media screen and (max-width: 767px) {       
    .public-card-header {
        padding: 16px 0 0;
        margin-bottom: 0;
        
        .public-card-logo {
            width: 88px;
            height: 16px;
        }
        
        &:after {
            margin: 8px -16px;
            width: auto;
            display: block;
        }
    }
}