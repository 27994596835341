.ant-checkbox-wrapper {

    &.align-center,
    &.tabee-login__remember {
        width: 100%;
        text-align: center;
    }

    &:hover {
        & {
            outline: none !important;
            box-shadow: none !important;
        }
    }

    &.small-size,
    &.tabee-login__remember {
        .ant-checkbox-inner {
            width: 2rem;
            height: 2rem;

            &:after {
                left: 10%;
                transform: scale(.7) translate(40%,-80%) rotate(45deg) ;
            }
        }
    }

    .ant-checkbox-checked {
        &:after {
            display: none;
        }
        
        .ant-checkbox-inner {
            border-color: var(--color-active-brand);
            color: var(--color-passive-light);

            &:before {
                transform: scale(1.1);
            }
        }
    }

    .ant-checkbox-inner {
        width: 3rem;
        height: 3rem;
        background: var(--color-passive-light);
        border: 1px solid;
        border-radius: 100%;
        color: var(--color-passive-mark);
        cursor: inherit;
        transition: all 0.25s;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--color-active-brand);
            border-radius: inherit;
            transition: transform 0.25s;
            transform: scale(0);
        }

        &:after {
            left: 26%;
            height: 1.5rem;
            width: .9rem;
            border-width: .375rem;
            transition: none;
        }
    }
    
    &.dark-theme {
        color: var(--color-passive-light);

        &.small-size,
        &.tabee-login__remember {
            .ant-checkbox-inner {
                &:after {
                    left: 10%;
                }
            }
        }
        
        .ant-checkbox-inner {
            background-color: var(--color-passive-dark);
            border-color: var(--color-passive-dark);
            color: var(--color-passive-dark);
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: var(--color-active-brand);
        }

        .ant-checkbox-disabled {
            .ant-checkbox-inner {
                opacity: .5;
                border-color: transparent !important;

                &:after {
                    border-color: #fff;
                }
            }

            & + span {
                color: rgba(255,255,255,.5);
            }
        }
    }
}